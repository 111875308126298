html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
}

body {
  background-image: url('./pokemon.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: black;
}

a {
  text-decoration: none;
  color: inherit;
}

section {
  height: 100vh;
  width: 100vw;
  scroll-snap-align: center;
  overflow: hidden;
}