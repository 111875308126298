.navbar {
    height: 100px;

    .wrapper {
      max-width: 1366px;
      margin:auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }
    img {
      width: 30px;
      
    }

    span{
      font-weight: bold;
    }

    .social {
      display: flex;
      align-items: center;
      gap: 10px;
    }
}