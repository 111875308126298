.project {
  position: relative;
  
  .progress {
    position: sticky;
    top: 0;
    left: 0;
    padding-top: 50px;
    text-align: center;
    color: orange;
    font-size: 36px;

    .progressBar {
      height: 10px;
      background-color: black;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh; /* Ensure the container fills the viewport height */
    overflow: hidden;

    .wrapper {
      max-width: 1366px;
      width: 100%; /* Ensure the wrapper fills the container width */
      height: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 50px;

      .imageContainer {
        flex: 1;
        max-width: 600px; /* Set a max width for the image container */
        height: auto; /* Adjust height automatically */
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          max-width: 100%; /* Ensure the image does not exceed the container's width */
          height: auto; /* Maintain aspect ratio */
          object-fit: cover; /* Cover the container area */
          border-radius: 10px; /* Optional: Add border-radius for styling */
        }
      }

      .textContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 30px;
        max-width: 600px; /* Optional: Set a max width for text */

        h2 {
          font-size: 72px;
          margin: 0;
        }

        p {
          color: black;
          font-size: 20px;
          margin: 0;
          background-color: rgba(173, 216, 230, 0.9); /* Match the experience container background color */
          border-radius: 5px;
          padding: 15px; /* Add padding for better spacing */
          transition: background 0.3s ease; /* Smooth background transition on hover */
        }

        p:hover {
          background-color: rgba(255, 255, 255, 0.8); /* Change the background color on hover */
        }

        button {
          background-color: orange;
          border: none;
          border-radius: 10px;
          padding: 10px;
          width: 200px;
          cursor: pointer;
        }
      }
    }
  }
}
