.pokeballs {
  position: relative;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  background-size: cover;
}

.pokeball, .mpokeball, .upokeball, .gpokeball, .TRpokeball {
  position: relative;
  margin: 20px;
  text-align: center;
  width: 150px;
  height: 150px;
  display: inline-block; /* Ensure it acts like a button */
  border: none; /* Remove default styles if any */
  background: none; /* Remove any background */
  text-decoration: none; /* Remove underline from links */
}

.pokeball img, .mpokeball img, .upokeball img, .gpokeball img, .TRpokeball img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.label {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: black;
  font-weight: bold;
}

/* Responsive adjustments for larger screens */
@media (min-width: 1024px) {
  .pokeball, .mpokeball, .upokeball, .gpokeball, .TRpokeball {
    width: 200px; /* Resize for larger screens */
    height: 200px;
  }

  .label {
    font-size: 18px; /* Adjust font size */
  }
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .pokeball, .mpokeball, .upokeball, .gpokeball, .TRpokeball {
    width: 120px; /* Resize for smaller screens */
    height: 120px;
  }

  .label {
    font-size: 14px; /* Adjust font size */
  }
}

@media (max-width: 480px) {
  .pokeball, .mpokeball, .upokeball, .gpokeball, .TRpokeball {
    width: 100px; /* Resize for very small screens */
    height: 100px;
  }

  .label {
    font-size: 12px; /* Adjust font size */
  }
}
