.dropdown{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: gray;
  color: black;
  z-index: 1000;
  position: relative;

  .bg {

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 350px;
    background: white;
    
    


    .links {
     position: absolute;
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     gap: 20px;

     a{
      font-size: 40px;
     }
    }
  }
  
  button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: fixed;
    top: 25px;
    left: 25px;
    background-color: transparent;
    border: none;
    cursor : pointer;
  }
}