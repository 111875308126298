


.contact {
  height: 100%;
  max-width: 1366px;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 50px;

  

  .textContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;

   

    h1 {
      font-size: 100px;
      line-height: 88px;

     
    }

    span {
      font-weight: 300;
    }
  }

  .formContainer {
    flex: 1;
    position: relative;
    
    


    .phoneSvg {
      stroke: orange;
      position: absolute;
      margin: auto;
      z-index: -1;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      input,
      textarea {
        padding: 20px;
        background-color: white;
        border: 1px solid white;
        color: white;
        border-radius: 5px;

        
      }

      button {
        padding: 20px;
        border: none;
        background-color: orange;
        cursor: pointer;
        font-weight: 500;
       
      }
    }
  }
}
